import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  ${({ type }) =>
    type === 'next'
      ? `
        right: 0;
        // transform: rotateZ(180deg);
       `
      : `
        left: 0;
        transform: rotateZ(180deg);
        `}
  background: transparent;
  border: none;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  outline: none;
  padding: 0 20px;
  position: absolute;
  top: 0;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
  z-index: 4;

  > div {
    background: #fff;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
    }
  }
`

const CarouselButton = ({ onClick, type, children }) => (
  <StyledButton type={type} className={type} onClick={onClick}>
    <div>
      <img src="/images/arrow-circle-right.png" alt={`${type} arrow symbol`} />
    </div>
  </StyledButton>
)

export default CarouselButton
