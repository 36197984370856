import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

import CarouselBlock from './CarouselBlock'
import SliderBlock from './SliderBlock'
import { mediaMaxWidth } from '../styles/media'

const StyledBlock = styled.div`
  margin-bottom: 48px;
  &:last-child {
    margin-bottom: 0px;
  }
  > .block-title-bar {
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    height: 37px;
    justify-content: space-between;
    margin: 0 80px 16px 80px;
    > .block-title-text {
      line-height: 1;
      text-transform: uppercase;
    }
    > a {
      color: #f47521;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-decoration: none;
      > span {
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
      > svg {
        padding-top: 2px;
      }
    }
  }

  @media (max-width: 1140px) {
    > .block-title-bar {
      margin: 0 20px 16px 20px;
    }
  }
  ${mediaMaxWidth('tablet')`
    margin-bottom: 36px;
  `}
  ${mediaMaxWidth('mobile')`
    margin-bottom: 32px;
  `}
`

const Block = ({ title, tiles, slug }) => {
  const [showCarousel, setShowCarousel] = useState(true)
  useEffect(() => {
    setShowCarousel(!isMobile)
  }, [isMobile])
  return (
    <StyledBlock>
      <div className="block-title-bar">
        <h3 className="block-title-text">{title}</h3>
        <Link to={`/${slug}`}>
          <span>See All</span>&nbsp;&nbsp;
          <ArrowSvg />
        </Link>
      </div>
      {showCarousel ? (
        <CarouselBlock tiles={tiles} />
      ) : (
        <SliderBlock tiles={tiles} />
      )}
    </StyledBlock>
  )
}

const ArrowSvg = () => (
  <svg
    width="8px"
    height="12px"
    viewBox="0 0 8 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>F85AB440-6574-4E27-ADA4-47B12458CBAC</title>
    <desc>Created with sketchtool.</desc>
    <g
      id="111319---Landing-Pages-Updates"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="RecipeLanding_Desktop_1280"
        transform="translate(-1192.000000, -1080.000000)"
        fill="#F47521"
        fillRule="nonzero"
      >
        <g id="CATEGORY_ROWS" transform="translate(-50.000000, 675.000000)">
          <g
            id="Lunch-&amp;-Dinner"
            transform="translate(0.000000, 399.000000)"
          >
            <g id="TitleBar" transform="translate(130.000000, 0.000000)">
              <g id="ViewAll" transform="translate(1060.000000, 3.000000)">
                <path
                  d="M59.7665438,4.13834032 C60.0778187,3.87687055 60.0778187,3.45601802 59.7665438,3.19454026 C59.4527694,2.93515325 58.9477292,2.93515325 58.6339452,3.19454026 L52.2334562,8.52809997 C51.9221813,8.78956974 51.9221813,9.21042226 52.2334562,9.47190003 L58.6339452,14.8054597 C58.9477196,15.0648468 59.4527598,15.0648468 59.7665438,14.8054597 C60.0778187,14.54399 60.0778187,14.1231374 59.7665438,13.8616597 L53.9312179,9.00012001 L59.7665438,4.13834032 Z"
                  id="arrow_8x12"
                  transform="translate(56.000000, 9.000000) rotate(-180.000000) translate(-56.000000, -9.000000) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Block
