import React from 'react'
import styled from 'styled-components'

import { mediaMaxWidth } from '../styles/media'

import Tile from './Tile'

const SliderBlock = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0 80px;
  > *:last-child {
    opacity: 0;
    margin-left: 75px;
  }
  &::-webkit-scrollbar {
    /* to hide scrollbar */
    display: none;
  }
  @media (max-width: 1140px) {
    padding: 0 20px;
    > *:last-child {
      opacity: 0;
      margin-left: 15px;
    }
  }
`
const Block = ({ tiles }) => {
  return (
    <SliderBlock>
      {tiles.map((tile, i) => (
        <Tile data={tile} key={i} />
      ))}
      <div className="gap">&nbsp;</div>
    </SliderBlock>
  )
}

export default Block
