import { useState, useRef, useEffect } from 'react'

const PADDINGS = 0 // left and right padding of container
const MARGIN = 20 // left and right margins of each element
// const MARGINS = 20 * 12; // left and right margins of all elements

const useCarousel = (elementWidth, totalElements, windowSize) => {
  const containerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [distance, setDistance] = useState(0)
  const [elementsInViewport, setElementsInViewport] = useState(0)
  const [viewedElements, setViewedElements] = useState(0)

  const [elementOverflow, setElementOverflow] = useState(0)
  const [travel, setTravel] = useState(0)

  useEffect(() => {
    const containerWidth = containerRef.current.clientWidth - PADDINGS

    setContainerWidth(containerWidth)
    setElementsInViewport(Math.floor(containerWidth / (elementWidth + MARGIN)))

    setElementOverflow(
      elementWidth + MARGIN - (containerWidth % (elementWidth + MARGIN))
    )
  }, [containerRef.current, windowSize])

  const handlePrev = () => {
    setViewedElements(viewedElements - elementsInViewport + 0.5)
    const diff =
      (elementWidth + MARGIN) * elementsInViewport + (elementWidth + MARGIN) / 2

    const newDistance = distance - diff < 0 ? 0 : distance - diff
    setDistance(newDistance)
    setTravel(travel - 1)
  }

  const handleNext = () => {
    setViewedElements(viewedElements + elementsInViewport - 0.5)
    const diff =
      (elementWidth + MARGIN) * elementsInViewport - (elementWidth + MARGIN) / 2

    const maxDistance =
      (elementWidth + MARGIN) * totalElements - containerWidth / 2

    const newDistance =
      distance + diff > maxDistance ? maxDistance : distance + diff

    setDistance(newDistance)
    setTravel(travel + 1)
  }

  const slideProps = {
    style: { transform: `translate3d(-${distance}px, 0, 0)` },
  }

  const hasPrev = distance > 0
  const hasNext = viewedElements + elementsInViewport < totalElements

  return { handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext }
}

export default useCarousel
