import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import formatTime from '../../lib/formatTime'
import { mediaMaxWidth } from '../styles/media'
import { Context } from './CarouselBlock'

const SliderTile = styled(Link)`
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-sizing: border-box;
  color: #404040;
  display: flex;
  flex: 0 0 330px;
  flex-flow: column nowrap;
  text-decoration: none;
  transition: transform 300ms ease 100ms;
  & + * {
    margin-left: 20px;
  }
  > .image-container {
    background: #e0e0e0;
    border-radius: 5px 5px 0 0;
    height: 186px;
    margin: 0;
    img {
      background: #e0e0e0;
      border-radius: 5px 5px 0 0;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  > .tile-text {
    display: flex;
    flex-flow: column nowrap;
    height: calc(298px - 186px); /* totalheight - imgheight - padding x 2 */
    justify-content: space-between;
    padding: 16px;
    > .tile-text-title {
      /* to truncate title text; see resouce: https://css-tricks.com/line-clampin/ */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      > * {
        color: #1a1a1a;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    > .tile-text-sub {
      color: #757575;
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      margin: 0;
    }
  }
  ${mediaMaxWidth('tablet')`
    flex: 0 0 290px;
    > .image-container {
      height: 163px;
    }
    > .tile-text {
      height: calc(
      275px - 163px
    );
    }
  `}

  ${mediaMaxWidth('mobile')`
    flex: 0 0 240px;
    & + * {
      margin-left: 16px;
    }
    > .image-container {
      height: 134px;
    }
    > .tile-text {
      height: calc( 226px - 134px);
      padding: 12px;
      
      .tile-text-title {
        > h2 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
        }
        > h3 {
          line-height: 8px;
          font-size: 14x;
          margin: 4px 0 12px 0;
        }
      }
      > .tile-text-sub {
        font-size: 12px;
        line-height: 14px;
      }
    }
  `}
`
const Tile = ({
  data: {
    title,
    sauceTitle: subtitle,
    mediaUrl,
    slug,
    totalTimeMinutes,
    prepTimeMinutes,
    cookTimeMinutes,
    inactivePrepTimeMinutes,
    contentfulMedia,
    // ...rest
  },
  // ...other
}) => {
  const { elementRef } = useContext(Context)

  const time = formatTime(totalTimeMinutes)
  const image =
    contentfulMedia && contentfulMedia.file && contentfulMedia.file.url
      ? contentfulMedia.file.url
      : mediaUrl

  return (
    <SliderTile className="item" to={`/recipes/${slug}`} ref={elementRef}>
      <div className="image-container">
        {image && <img src={image.replace('http:', 'https:')} alt={title} />}
      </div>
      <div className="tile-text">
        <div className="tile-text-title">
          <h2>
            {title}
            {subtitle && ` with ${subtitle}`}
          </h2>
        </div>
        <p className="tile-text-sub">{time}</p>
      </div>
    </SliderTile>
  )
}

export default Tile
