import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const HeroContainer = styled.div`
  height: 500px;
  margin-bottom: 36px;
  position: relative;
  width: 100%;
  > .hero-image {
    height: 100%;
    width: 100%;
    &.tablet,
    &.mobile {
      display: none;
    }
  }
  > .hero-content {
    --side-padding: 20px;
    left: 50%;
    letter-spacing: 0.4px;
    margin: 0 auto;
    padding: 0 var(--side-padding);
    position: absolute;
    top: 60%;
    transform: translate(-50%, -50%);
    max-width: calc(1120px + var(--side-padding) * 2);
    width: 100%;
    > .hero-title {
      color: #ffffff;
      font-size: 52px;
      font-weight: 600;
      line-height: 62px;
      text-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 16px;
      max-width: 50%;
    }
    > .hero-subtitle {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
      @media (max-width: 1440px) {
        max-width: 50%;
      }
    }
  }
  @media (max-width: 1024px) {
    height: 400px;
    margin-bottom: 32px;
    > .hero-image {
      &.desktop {
        display: none;
      }
      &.tablet {
        display: inherit;
      }
    }
    > .hero-content {
      > .hero-title {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 12px;
      }
      > .hero-subtitle {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  @media (max-width: 768px) {
    height: 300px;
    margin-bottom: 28px;
    > .hero-image {
      &.tablet {
        display: none;
      }
      &.mobile {
        display: inherit;
      }
    }
    > .hero-content {
      text-align: center;
      > .hero-title {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 8px;
        max-width: none;
      }
      > .hero-subtitle {
        font-size: 18px;
        line-height: 28px;
        max-width: none;
      }
    }
  }
`

const Hero = ({ desktop, tablet, mobile }) => {
  return (
    <HeroContainer>
      <Img
        alt="hestan cue recipes hero image"
        fluid={desktop.fluid}
        className="hero-image desktop"
      />
      <Img
        alt="hestan cue recipes hero image"
        fluid={tablet.fluid}
        className="hero-image tablet"
      />
      <Img
        alt="hestan cue recipes hero image"
        fluid={mobile.fluid}
        className="hero-image mobile"
      />
      <div className="hero-content">
        <h3 className="hero-title">Recipes Perfected from Prep to Plating</h3>
        <p className="hero-subtitle">
          Follow along with our video guided recipes for delicious results every
          time.
        </p>
      </div>
    </HeroContainer>
  )
}

// const Hero = ({ data }) => (
//   <StyledDiv className="hero-area">
//     <div className="hero-content">
//       <div>
//         <h2>Lorem Ipsum Dolor Sit Amet</h2>
//         <p>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//           eiusmod tempor
//         </p>
//         <button>Lorem Ipsum</button>
//       </div>
//     </div>
//   </StyledDiv>
// )
export default Hero
