import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import { mediaMaxWidth } from '../../styles/media'
import useWindowSize from './hooks/useWindowSize'
import useElementWidth from './hooks/useElementWidth'
import useCarousel from './hooks/useCarousel'

import Button from './Button'
import Tile from '../Tile'

export const Context = React.createContext({ elementRef: null })

const CarouselBlock = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  &:hover {
    > button {
      /* background: linear-gradient(
        90deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      ); */
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      opacity: 1;
    }
  }
  > div {
    display: flex;
    padding: 0 80px;
    transition: transform 300ms ease 100ms;
    width: 100%;
    z-index: 3;
    @media (max-width: 1140px) {
      padding: 0 20px;
    }
  }
`
const Block = ({ title, tiles, activeSlide }) => {
  const windowSize = useWindowSize()
  const [currentSlide, setCurrentSlide] = useState(activeSlide)
  const { elementWidth, elementRef } = useElementWidth()
  const {
    handlePrev,
    handleNext,
    slideProps,
    containerRef,
    hasNext,
    hasPrev,
  } = useCarousel(elementWidth, tiles.length, windowSize.width)

  const handleSelect = movie => {
    setCurrentSlide(movie)
  }

  const handleClose = () => {
    setCurrentSlide(null)
  }

  const contextValue = {
    onSelectSlide: handleSelect,
    onCloseSlide: handleClose,
    elementRef,
    currentSlide,
  }

  return (
    <Context.Provider value={contextValue}>
      <CarouselBlock>
        <div ref={containerRef} {...slideProps}>
          {tiles.map((tile, i) => (
            <Tile data={tile} key={i} />
          ))}
        </div>
        {hasPrev && <Button onClick={handlePrev} type="prev" />}
        {hasNext && <Button onClick={handleNext} type="next" />}
      </CarouselBlock>
    </Context.Provider>
  )
}

export default Block
