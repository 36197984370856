import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import _ from 'lodash'
import { mediaMaxWidth } from '../components/styles/media'
import Layout from '../components/Layout'
import Block from '../components/Block'
import Hero from '../components/Hero'

const IndexPageLayout = styled(Layout)`
  max-width: none;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .category-rows {
    width: 100%;
  }
  > footer {
    &:before {
      left: 0;
      right: 0;
    }
  }
  @media (min-width: 1140px) {
    > footer {
      padding: 60px 80px 0px 80px;
    }
  }
`

class RootIndex extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const recipes = this.props.data.allContentfulRecipe.edges

    // Organizes recipes by main and sub categories
    let recipesByCategory = recipes.reduce((acc, recipe) => {
      // remove in development recipes from all others
      if (
        recipe.node.categories.filter(cat => cat.mainCategory === 'Custom')
          .length
      )
        return acc
      recipe.node.categories.forEach(({ mainCategory, subCategory }) => {
        if (acc[mainCategory]) {
          // if it has the main category
          if (acc[mainCategory][subCategory]) {
            // ...and has the sub category
            acc[mainCategory][subCategory].push(recipe.node)
          } else {
            // ... and doesn't have the sub category
            acc[mainCategory][subCategory] = [recipe.node]
          }
        } else {
          // if it doesn't have the main category (and sub category)
          acc[mainCategory] = { [subCategory]: [recipe.node] }
        }
      })
      return acc
    }, {})

    const breakfastRecipes = recipesByCategory['Meal / Course']['Breakfast']

    const dinnerRecipes = recipesByCategory['Meal / Course']['Dinner']

    const sidesRecipes = recipesByCategory['Meal / Course']['Side']

    const vegetarianRecipes =
      recipesByCategory['Dietary Restriction']['Vegetarian']

    const glutenFreeRecipes =
      recipesByCategory['Dietary Restriction']['Gluten Free']

    const nutFreeRecipes = recipesByCategory['Dietary Restriction']['Nut Free']

    const dairyFreeRecipes =
      recipesByCategory['Dietary Restriction']['Dairy Free']

    const dessertsRecipes = recipesByCategory['Meal / Course']['Desserts']
    const sweetsRecipes = recipesByCategory['Main Ingredient']['Sweets']
    const dessertsAndSweetsRecipes = Array.from(
      new Set([...dessertsRecipes, ...sweetsRecipes])
    )

    const inDevelopmentRecipes = recipes
      .filter(
        recipe =>
          !!recipe.node.categories.filter(cat => cat.mainCategory === 'Custom')
            .length
      )
      .map(rec => rec.node)

    return (
      <IndexPageLayout location={this.props.location}>
        <Hero
          desktop={this.props.data.heroImageDesktop}
          tablet={this.props.data.heroImageTablet}
          mobile={this.props.data.heroImageMobile}
        />
        <div className="category-rows">
          <Block title="Breakfast" tiles={breakfastRecipes} slug="breakfast" />
          <Block title="Lunch & Dinner" tiles={dinnerRecipes} slug="dinner" />
          <Block title="Sides" tiles={sidesRecipes} slug="side" />
          <Block
            title="Vegetarian"
            tiles={vegetarianRecipes}
            slug="vegetarian"
          />
          <Block
            title="Desserts & Sweets"
            tiles={sweetsRecipes}
            slug="sweets"
          />
          {inDevelopmentRecipes && inDevelopmentRecipes.length > 0 && (
            <Block
              title="Recipes In Development"
              tiles={inDevelopmentRecipes}
              slug="recipes-in-development"
            />
          )}
          {/* <Block
            title="Gluten Free"
            tiles={glutenFreeRecipes}
            slug="gluten-free"
          /> */}
          {/* <Block title="Nut Free" tiles={nutFreeRecipes} slug="nut-free" /> */}
          {/* <Block
            title="Dairy Free"
            tiles={dairyFreeRecipes}
            slug="dairy-free"
          /> */}
        </div>
      </IndexPageLayout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulRecipe {
      edges {
        node {
          title
          sauceTitle
          totalTimeMinutes
          cookTimeMinutes
          prepTimeMinutes
          inactivePrepTimeMinutes
          mediaUrl
          mediaType
          slug
          # contentfulMedia {
          #   file {
          #     url
          #   }
          # }
          categories {
            mainCategory
            subCategory
          }
        }
      }
    }
    heroImageDesktop: contentfulAsset(
      contentful_id: { eq: "1A9TBaYcADGxq9lV59QuEP" }
    ) {
      fluid(maxWidth: 2880, resizingBehavior: FILL) {
        ...GatsbyContentfulFluid
      }
    }
    heroImageTablet: contentfulAsset(
      contentful_id: { eq: "1b8Nsm0QjMue1LVlKpfY8a" }
    ) {
      fluid(maxWidth: 2048, resizingBehavior: FILL) {
        ...GatsbyContentfulFluid
      }
    }
    heroImageMobile: contentfulAsset(
      contentful_id: { eq: "3FZcNfVMBty6mmsqMW2oAe" }
    ) {
      fluid(maxWidth: 1536, resizingBehavior: FILL) {
        ...GatsbyContentfulFluid
      }
    }
  }
`
